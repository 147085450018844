






































































import { Component, Vue } from 'vue-property-decorator';

import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PanelHeaderStatusTypes } from '@/services/common/ui.service';

import { IApp } from '@/services/app-and-firmware-store.service';

import WbFileDownloader from '@/components/ui-elements/wb-file-downloader/wb-file-downloader.vue';
import { IModel } from '@/services/common/form-validate.service';
import { IListOneUserPayload, UserRoles } from '@/services/payloads/user.payload';
import { IListAppsDto, IListAppsPayload } from '@/services/api/app-and-firmware-api.service';

interface ISubscriptions {
	listApps: Subscription | null;
	ownData: Subscription | null;
}

@Component({
	name : 'wb-user-home',
	components : {
		WbFileDownloader,
	},
})
export default class WbUserHome extends Vue {
	public panelHeaderStatusTypes = PanelHeaderStatusTypes;
	public userRoles = UserRoles;
	public hideFilter = false;

	private subscriptions: ISubscriptions = {
		listApps : null,
		ownData : null,
	}

	public filterModel: IModel = {
		fields : {
			search : {
				placeholder : 'Search name',
				oldValue : '',
				value : '',
				required : false,
				focus : false,
				status : {},
				error : {},
			},
			select : {
				placeholder : 'Selection',
				oldValue : '-',
				value : '-',
				required : false,
				options : [
					{ value : '-', label : 'Show all', },
					{ value : 'RC', label : 'Show RC', },
					{ value : 'RELEASE', label : 'Show Release', }
				],
				focus : false,
				status : {},
				error : {},
			},
		},
		properties : {
			dirtyFields : [],
		},
	};

	public paginationOptions = {
		min : 1,
		current : 1,
		max : 1,
		pageSize : 25,
	};

	public apps: IApp[] = [];

	/**
     * CREATED
     */
	public created(): void {
		this.subscribeToStore();
		this.listApps();

		// eslint-disable-next-line no-console
		console.info('%c CREATE WbUserHome ', 'background: blue; color: #FFF');
	}

	public destroyed(): void {
		this.unsubscribeFromStore();

		// eslint-disable-next-line no-console
		console.info('%c DESTROY WbUserHome ', 'background: purple; color: #FFF');
	}

	private subscribeToStore(): void {
		this.subscriptions.listApps = this.$services.appAndFirmware.apps$
			.pipe(
				tap((apps: IListAppsPayload | null) => {
					if (apps) {
						this.apps = apps.flashers.sort((a: IApp, b: IApp) => {
							return +b.time - +a.time;
						});

						this.apps.forEach((app: IApp) => {
							this.$set(app, 'busy', false);
							app.url = `/api/app-and-firmware/download/flasher/${app.nexusAssetId}/${app.name}`;
						});
						this.paginationOptions.max = Math.ceil(apps.count / this.paginationOptions.pageSize);
					}
				})
			).subscribe();

		this.subscriptions.ownData = this.$services.user.profile$
			.pipe(
				tap((ownData: IListOneUserPayload | null) => {
					if (ownData) {
						this.hideFilter = ownData.privilege.developer;
					}
				})
			).subscribe();
	}

	private unsubscribeFromStore(): void {
		Object.keys(this.subscriptions).map(key => this.subscriptions[key as keyof ISubscriptions])
			.forEach((subscription: Subscription | null) => {
				if (subscription) {
					subscription.unsubscribe();
				}
			});
	}

	private listApps(): void {
		const dto: IListAppsDto = {
			page : this.paginationOptions.current,
			pageLength : this.paginationOptions.pageSize,
			search : this.filterModel.fields.search.value.toString(),
		};
		if (this.filterModel.fields.select.value === 'RC') {
			dto.showOnlyRc = true;
		} else if (this.filterModel.fields.select.value === 'RELEASE') {
			dto.showOnlyRelease = true;
		}
		this.$services.appAndFirmware.listApps(dto);
	}

	public onPaginationPageChange(newPageNumber: number): void {
		this.paginationOptions.current = newPageNumber;
		this.listApps();
	}

	public filterList(filter: string): void {
		this.$formValidation.onInput(this.filterModel, 'select', filter);
		this.listApps();
	}
}
